// import resume from '../assets/pdf/Bikash\'s Resume.pdf'
import resume from "../assets/pdf/Resume(BIKASH SHRESTHA).pdf";
import image from "../assets/images/bikash.jpg";

export const headerData = {
  name: "Bikash Shrestha",
  title: "Software Engineer",
  desciption:
    "There wasn't a bird in the sky, but that was not what caught my attention. It was the clouds. The deep green that isn't the color of clouds, but came with these. I knew what was coming and I hoped I was prepared. ",
  // image: 'https://scontent.fktm16-1.fna.fbcdn.net/v/t39.30808-6/241625603_1518776695125813_4721286616823943996_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=KIkT-YmDeFQAX-hm4h7&_nc_ht=scontent.fktm16-1.fna&oh=00_AT81O48ybBfRs6fDJUxXEz9so3LaBADZ4DXXNT6zOAFbrA&oe=62CD7893',
  // image: 'https://scontent.fktm16-1.fna.fbcdn.net/v/t39.30808-6/259977918_1570916389911843_576067029074054903_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_ohc=3yiXazUIs3wAX9xeRxl&tn=Q4mQv_PU-Ol3eVRk&_nc_ht=scontent.fktm16-1.fna&oh=00_AT-KXcEptyEHeFgpxGlxsYa735Oe2CoFZYchRUDY2Nvx5g&oe=62CD4D10',
  image: image,
  resumePdf: resume,
  resumePdfLink:
    "https://drive.google.com/file/d/1xvDZ6tuyr5mAts7BxF4_w3T33kt4wLA9/view?usp=sharing",
};
